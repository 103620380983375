.ContactSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 100%;   
}

.contact-content{
    margin-top: auto;
}

.contact-content h3{
    font-family: var(--font-mono);
    font-weight: 100;
    color: var(--green);
}


.contact-content h1{
    font-family: var(--font-sans);
    font-weight: 600;
    color: var(--light-purple);
    font-size: 4rem;
    margin: 0px;
}

.contact-p{
    font-family: var(--font-sans);
    color: var(--muted-text-color);
    font-weight: 400;
    line-height: 25px;
    /* Hacky way of putting it in the middle. */
    padding-left: 25%;
    padding-right: 25%;
    margin: 0px;
}


.Contact_Button{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: var(--font-mono);
    width: fit-content;
    color: var(--green);
    margin-top: 2rem;
    padding: 1rem;
    border: solid 1px var(--green);
    border-radius: 5px;
}

.Contact_Button a{
    text-decoration: none;
    color: var(--green);
    font-weight: 200;
}


.Contact_Footer{
    margin-top: auto;
    color: var(--muted-text-color);
    opacity: 0.6;
    padding-bottom: 10px;
    
}

.Contact_Footer p{
    font-family: var(--font-mono);
    font-size: 12px;
    line-height: 5px;
    letter-spacing: 1px;
}

.Contact_Footer:hover{
    color: var(--green);
}


@media screen and (max-width: 768px){
    .contact-content h1{
        font-size: 3rem;
    }

    .contact-p{
        padding-left: 10%;
        padding-right: 10%;
    }
}